/** @jsx jsx */
import React, {useContext} from 'react';
import {StaticImage} from 'gatsby-plugin-image';

import {jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import SEO from '../../components/common/SEO';
import ProductHero from '../../components/common/ProductHero';
import ProductPageContainer from '../../components/common/ProductPageContainer';
import {Hero, H1, H5, h4BaseStyle, h6BaseStyle} from '../../components/common/Typography';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import AnimatedBackground from '../../components/common/AnimatedBackground';
import {CTA} from '../../components/common/Clickables';
import {ContrastContext} from '../../helpers/context';
import TrapezoidBackground from '../../images/product/vault/trapezoid.svg';
import ShapesBackground from '../../images/product/vault/shapes.png';
import TokenizationDesktopImage from '../../images/product/vault/tokenization-desktop.png';
import TokenizationMobileImage from '../../images/product/vault/tokenization-mobile.png';


/*
 * Constants
 */
/* eslint-disable-next-line i18next/no-literal-string */
const videoUrl = null;


/*
 * Private Elements
 */
const DataProtectionContainer = styled.div`
    position: relative;

    .row {
        margin-top: 30px;

        &.shield-one {
            margin-top: 50px;
        }
    }

    ${ContentContainer} {
        padding-left: 25px;
        padding-right: 25px;

        ${Hero} {
            margin-bottom: 25px;
        }

        ${H5} {
            font-weight: ${fontWeights.regular};
            margin-bottom: 20px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .row {
            margin-top: 50px;

            &.shield-one {
                margin-top: 100px;
            }
        }

        ${ContentContainer} {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

const CustomerCheckoutContainer = styled.div`
    position: relative;
    padding-bottom: 60px;

    ${ContentContainer} {
        position: relative;
        padding-top: 50px;
        padding-left: 25px;
        padding-right: 25px;

        ${H1} {
            margin-bottom: 25px;
        }

        ${H5} {
            font-weight: ${fontWeights.regular};
            margin-bottom: 50px;
        }

        .checkout-image {
            margin-bottom: 50px;
        }
    }

    .background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        .trapezoid-wrapper {
            position: absolute;
            width: 100%;
            height: 75%;
            bottom: 0;
            left: 0;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .shapes-wrapper {
            .image-wrapper {
                overflow: hidden;
                position: absolute !important;
                height: 55%;
                width: 100vw;
                top: 25%;
                left: 0;

                img {
                    height: 100%;
                }
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        padding-bottom: 130px;

        ${ContentContainer} {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;

            .row {
                &:not(.lean) {
                    margin-top: 50px;
                }
            }

            .checkout-image {
                margin-top: -4%;
            }
        }

        .background {
            bottom: 0;

            .trapezoid-wrapper {
                height: 115%;
            }

            .shapes-wrapper {
                .image-wrapper {
                    height: 100%;
                    max-width: 100%;
                    top: 0;
                }
            }
        }
    }
`;

const NetworkTokenizationContainer = styled.div`
    position: relative;
    color: ${colors.white};

    ${ContentContainer} {
        padding-left: 25px;
        padding-right: 25px;

        .content-wrapper {
            ${H1} {
                margin-top: 90px;
            }

            ${H5} {
                font-weight: ${fontWeights.regular};
                margin-top: 25px;
            }
        }
    }

    .background {
        height: 100vw;
        background: url(${TokenizationMobileImage}) no-repeat center/cover;
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            padding: clamp(25px, 10vw, 200px) 0;
            height: 100%;

            .content-wrapper {
                height: 100%;
                align-items: center;

                ${H1} {
                    margin-top: 0;
                    margin-bottom: 25px;
                }
            }
        }

        .background {
            position: absolute;
            width: 70%;
            top: 0;
            right: 0;
            height: 100%;
            background: url(${TokenizationDesktopImage}) no-repeat center/cover;
        }
    }
  
    @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
        ${ContentContainer} {
            .content-wrapper {
                ${H1} {
                    ${h4BaseStyle};
                }

                ${H5} {
                    ${h6BaseStyle};
                    font-weight: ${fontWeights.regular};
                }
            }
        }
    }
`;


/*
 * Public Elements
 */
const VaultPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    const section = t('Vault');
    // eslint-disable-next-line i18next/no-literal-string
    const sectionStr = 'Vault';
    const hero = t('Store payment credentials for multichannels use.');
    const description = t('Save customers\' payment details in a PCI DSS compliant manner for faster checkouts and '
        + 'optimized sales conversions.');
    const image = (
        <StaticImage src="../../images/product/vault/logo.png" placeholder="none" loading="eager" alt="" />
    );
    const mobileImage = (
        <StaticImage src="../../images/product/vault/logo-mobile.png" placeholder="none" loading="eager" alt="" />
    );

    return (
        <>
            <SEO
                title={section}
                ogTitle={t('Optimize Authorization Rates with Tokenization - Switch')}
                description={t('Save payment details and tokenize card data in a PCI compliant manner. Avoid declines '
                    + 'with network tokenization. Speed up checkouts and optimize conversions.')}
                image="/website/metatags/vault.png"
            />

            <ProductHero
                section={section}
                sectionStr={sectionStr}
                hero={hero}
                description={description}
                image={image}
                mobileImage={mobileImage}
                videoUrl={videoUrl}
            />

            <ProductPageContainer>
                <DataProtectionContainer>
                    <div className="row no-gutters shield-one">
                        <div className="col-4 col-lg-3">
                            <StaticImage
                                src="../../images/product/vault/shield-1.png"
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                    </div>
                    <ContentContainer>
                        <div className="row no-gutters">
                            <div className="col-12 col-md-5 offset-md-1">
                                <Hero><Trans>Protect your customers&apos; data at all times.</Trans></Hero>
                            </div>
                            <div className="col-12 col-md-4 offset-md-1">
                                <H5>
                                    <Trans>
                                        With the Switch ability to create vaults, you can securely store and tokenize
                                        payment details and customer&apos;s data. All while being compliant with the
                                        latest data regulations across the globe, including GDPR.
                                    </Trans>
                                </H5>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 col-md-11 offset-md-1">
                                <CTA to="/learn/607991475c98ce001052b3ef" colored>
                                    <Trans>Read more about Securing Personal Data</Trans>
                                </CTA>
                            </div>
                        </div>
                    </ContentContainer>
                    <div className="row no-gutters">
                        <div className="col-8 offset-4 col-lg-7 offset-lg-5 col-xl-6 offset-xl-6">
                            <StaticImage
                                src="../../images/product/vault/shield-2.png"
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                    </div>
                </DataProtectionContainer>

                <CustomerCheckoutContainer>
                    <div className="background">
                        <div className="trapezoid-wrapper">
                            <TrapezoidBackground />
                        </div>

                        <div className="shapes-wrapper">
                            <div className="image-wrapper">
                                {/* Due to the positioning of the image, we cannot use gatsby-image for this */}
                                <img src={ShapesBackground} className="background-image" alt="" />
                            </div>
                        </div>
                    </div>

                    <ContentContainer>
                        <div className="row no-gutters lean">
                            <div className="col-12 col-md-5 offset-md-1 checkout-image">
                                <StaticImage
                                    src="../../images/product/vault/checkout.png"
                                    placeholder="tracedSVG"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-4 offset-md-1">
                                <div className="row no-gutters">
                                    <H1><Trans>Make your customers&apos; experience smoother and faster.</Trans></H1>
                                    <H5>
                                        <Trans>
                                            Deliver a sleek experience to your customers providing the flexibility to
                                            reuse their payment details for a quicker checkout even in different payment
                                            channels. Increase sales while maintaining security standards.
                                        </Trans>
                                    </H5>
                                </div>
                                <div className="row no-gutters lean">
                                    <div className="col-12">
                                        <CTA to="https://switchpayments.com/docs/vault" target="_blank" colored>
                                            <Trans>Learn more about our Vault API</Trans>
                                        </CTA>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ContentContainer>
                </CustomerCheckoutContainer>

                <NetworkTokenizationContainer>
                    <AnimatedBackground>
                        <ContentContainer>
                            <div className="row no-gutters content-wrapper">
                                <div className="col-12 col-md-5 offset-md-1">
                                    <H1><Trans>Reduce declines with Network Tokenization.</Trans></H1>
                                    <H5>
                                        <Trans>
                                            Put an end to declines related to fraud, expired cards, or lost credentials.
                                            Network tokens are interoperable at every level — issuer, merchant, gateway,
                                            and acquirer. This means you can disregard the PCI scope and the risk of a
                                            breach is no longer relevant.
                                        </Trans>
                                    </H5>
                                </div>
                            </div>
                        </ContentContainer>
                        <div className="background" />
                    </AnimatedBackground>
                </NetworkTokenizationContainer>
            </ProductPageContainer>
        </>
    );
};


/*
 * Exports
 */
export default VaultPage;
